"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPodAdministratorDtoSchema = exports.resetUserPasswordDtoSchema = exports.resetUserMfaDtoSchema = exports.userPostDtoSchema = void 0;
const zod_1 = require("zod");
const fields_1 = require("./fields");
exports.userPostDtoSchema = fields_1.userInputFields;
exports.resetUserMfaDtoSchema = zod_1.z.object({
    userName: zod_1.z.string(),
});
exports.resetUserPasswordDtoSchema = zod_1.z.object({
    upn: zod_1.z.string(),
    email: zod_1.z.string().email(),
    name: zod_1.z.string(),
    podId: zod_1.z.string(),
});
exports.createPodAdministratorDtoSchema = zod_1.z.object({
    userPrincipalName: zod_1.z.string(),
    displayName: zod_1.z.string(),
    podId: zod_1.z.string(),
    email: zod_1.z.string().email(),
    readOnly: zod_1.z.boolean(),
    dataAdmin: zod_1.z.boolean(),
    userAdmin: zod_1.z.boolean(),
});
