"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vmDtoSchema = exports.vmInputFields = void 0;
const zod_1 = require("zod");
/** Database fields set by user input that can be queried via the api */
const vmInputFields = zod_1.z.object({
    /** The vm size identifier to assign */
    size: zod_1.z.string(),
    /** The vm type identifier to assign */
    type: zod_1.z.string(),
    /** The vm version identifier to assign */
    version: zod_1.z.string(),
});
exports.vmInputFields = vmInputFields;
/** Generated database fields that can be queried via the api */
const vmGeneratedFields = zod_1.z.object({
    VMname: zod_1.z.string(),
    project: zod_1.z.string(),
    buildDate: zod_1.z.string().datetime(),
    destroyDate: zod_1.z.string().datetime(),
    status: zod_1.z.enum(['READY', 'BUILDING', 'DESTROYED', 'DORMANT', 'ERROR']),
    powerState: zod_1.z.enum(['RUNNING', 'DEALLOCATED']),
    assignedTo: zod_1.z.string(),
    localDiskSize: zod_1.z.number(),
});
/** All database fields that are exposed via API */
const vmDtoSchema = vmInputFields.merge(vmGeneratedFields);
exports.vmDtoSchema = vmDtoSchema;
