import React, {
  useState, useEffect,
} from 'react'
import flow from 'lodash.flow'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Typography,
  Button,
  Container,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { getRuntimeConfig } from '../../util/Config'
import { withSnackbarsFeature, withConfirmFeature, withLoadingFeature } from '../../screenWrappers/Features'
import SkeletonForm from '../../components/SkeletonForm'
import { appUserShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'

const config = getRuntimeConfig()
const { AAD_SUFFIX } = config

// Screen requires the following data providers and features injected
const wrap = flow([
  withLoadingFeature,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

const form = {
  userPrincipalName: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  readOnly: PropTypes.bool,
  dataAdmin: PropTypes.bool,
  userAdmin: PropTypes.bool,
}

const formPropTypes = {
  values: PropTypes.shape(form).isRequired,
  touched: PropTypes.shape(Object.fromEntries(Object.keys(form).map((k) => [k, PropTypes.bool]))).isRequired,
  errors: PropTypes.shape(Object.fromEntries(Object.keys(form).map((k) => [k, PropTypes.string]))).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
}

function Form({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  updating,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <p className="mandatory-info">All fields are required</p>

      <Grid container spacing={3} alignItems="center" direction="column">

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="userPrincipalName">Username</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                id="userPrincipalName"
                aria-describedby="helperuserPrincipalName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userPrincipalName}
                disabled={updating}
              />

              <FormHelperText
                id="helperuserPrincipalName"
                error={errors.userPrincipalName && touched.userPrincipalName}
              >
                {(errors.userPrincipalName && touched.userPrincipalName) ? errors.userPrincipalName
                  : 'The username of the Administrator'}
              </FormHelperText>
            </FormControl>
          </Grid>
          {!updating
            && (
              <Grid item xs={6}>
                <InputLabel>{AAD_SUFFIX}</InputLabel>
              </Grid>
            )}
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="displayName">Name</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                maxRows="4"
                id="displayName"
                aria-describedby="helperName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.displayName}
              />
              <FormHelperText
                id="helperName"
                error={errors.displayName && touched.displayName}
              >
                {(errors.displayName && touched.displayName) ? errors.displayName
                  : 'The display name for the Administrator'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="email">Email</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                id="email"
                aria-describedby="helperEmail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                autoComplete="email"
              />
              <FormHelperText
                id="helperEmail"
                error={errors.email && touched.email}
              >
                {(errors.email && touched.email) ? errors.email
                  : 'Organisation contact email'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {!updating

          && (
            <>
              <Grid
                container
                className="formRow"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                direction="row"
              >
                <Grid item>
                  <Typography variant="h6" component="h2">
                    Permissions
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className="formRow"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                direction="row"
              >
                <Grid item xs={2}>
                  <InputLabel htmlFor="reader">Reader</InputLabel>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <Checkbox
                      id="reader"
                      style={{ width: '42px' }}
                      checked
                      disabled
                      aria-describedby="helperreader"
                    />
                    <FormHelperText
                      id="helperreadonly"
                    >
                      View metadata such as Users, Projects, and Products.
                      Does not include read access to actual data in Azure storage.
                      This role is default for all Administrators
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                className="formRow"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                direction="row"
              >
                <Grid item xs={2}>
                  <InputLabel htmlFor="projectadmin">Project Admin</InputLabel>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <Checkbox
                      id="projectadmin"
                      style={{ width: '42px' }}
                      checked={!values.readOnly}
                      onChange={() => setFieldValue('readOnly', !values.readOnly)}
                      aria-describedby="helperprojectadmin"
                    />
                    <FormHelperText
                      id="helperprojectadmin"
                      error={errors.readOnly && touched.readOnly}
                    >
                      {
                        (errors.readOnly && touched.readOnly)
                          ? errors.readOnly
                          : 'Create and manage Projects, assign Analysts to Projects, link Projects to Products (does not include permission to create Products or access data)'
                      }
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                className="formRow"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                direction="row"
              >
                <Grid item xs={2}>
                  <InputLabel htmlFor="dataAdmin">Data Admin</InputLabel>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <Checkbox
                      id="dataAdmin"
                      style={{ width: '42px' }}
                      checked={values.dataAdmin}
                      onChange={() => setFieldValue('dataAdmin', !values.dataAdmin)}
                      aria-describedby="helperdataadmin"
                    />
                    <FormHelperText
                      id="helperdataadmin"
                      error={errors.dataAdmin && touched.dataAdmin}
                    >
                      {
                        (errors.dataAdmin && touched.dataAdmin)
                          ? errors.dataAdmin
                          : 'Create and manage Products, including permission to read and write data to Azure storage locations'
                      }
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                className="formRow"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                direction="row"
              >
                <Grid item xs={2}>
                  <InputLabel htmlFor="userAdmin">User Admin</InputLabel>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <Checkbox
                      id="userAdmin"
                      style={{ width: '42px' }}
                      checked={values.userAdmin}
                      onChange={() => setFieldValue('userAdmin', !values.userAdmin)}
                      aria-describedby="helperuseradmin"
                    />
                    <FormHelperText
                      id="helperuseradmin"
                      error={errors.userAdmin && touched.userAdmin}
                    >
                      {(errors.userAdmin && touched.userAdmin)
                        ? errors.userAdmin
                        : 'Create and manage Analyst user accounts'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
      </Grid>

      <div className="action-button-container">

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </div>

    </form>
  )
}

Form.propTypes = formPropTypes

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  askForConfirmationListener: PropTypes.func.isRequired,
  showSnackbarSuccess: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    '@odata.context': PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    userPrincipalName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  updating: PropTypes.bool,
}

const defaultProps = {
  updating: false,
  userData: undefined,
}

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 */
function NewAdministratorScreen({
  user, askForConfirmationListener, datalabFacade, showSnackbarSuccess,
  updating, userData, startLoading, stopLoading,
}) {
  const [error] = useState(null)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  if (error) {
    throw error
  }

  useEffect(() => {
    if (!updating || (updating && userData)) {
      setLoading(false)
    }
  }, [userData])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    navigate('/administrators')
  }

  const handleSubmitNew = ({
    userPrincipalName, displayName, email, readOnly, dataAdmin, userAdmin,
  }) => {
    const u = userPrincipalName + AAD_SUFFIX
    askForConfirmationListener(
      'Are you sure you want to create this Administrator?',
      async () => {
        await datalabFacade.createAdministrator({
          userPrincipalName: u,
          displayName,
          email,
          podId: user.pod,
          readOnly,
          dataAdmin,
          userAdmin,
        })
        showSnackbarSuccess(`Create Administrator (${u}) succeeded`)
        handleClickOpen()
      }
    )
  }

  const handleSubmitUpdate = ({ userPrincipalName, displayName, email }) => {
    askForConfirmationListener(
      'Are you sure you want to update this Administrator?',
      async () => {
        await datalabFacade.updateADUser({
          userPrincipalName, displayName, email, podId: user.pod,
        })
        showSnackbarSuccess(`Update Administrator (${userPrincipalName}) succeeded`)
      },
      { redirect: '/administrators' }
    )
  }

  const handleNewBtnClick = () => {
    setOpen(false)
    navigate('/administrators/new')
  }

  if (open) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          Pod Admin Creation in Progress
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An email will be sent to the admin containing their login details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
          <Button onClick={handleNewBtnClick}>Create another</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return loading ? <SkeletonForm />
    : (
      <div className="details-grid">
        <Container maxWidth="md">

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              userPrincipalName: updating ? userData.userPrincipalName : '',
              displayName: updating ? userData.displayName : '',
              email: updating ? userData.email ?? '' : '',
              readOnly: updating ? undefined : false,
              dataAdmin: updating ? undefined : true,
              userAdmin: updating ? undefined : true,
            }}
            onSubmit={(values) => {
              if (updating) {
                handleSubmitUpdate(values)
              } else {
                handleSubmitNew(values)
              }
            }}
            validationSchema={Yup.object().shape({
              userPrincipalName: updating ? Yup.string()
                : Yup.string()
                  .matches(/^\w+([.-]?\w+)*$/, 'May only contain characters, numbers, fullstops, and hyphens')
                  .test(
                    'isUnique',
                    'This username already exists',
                    async (name) => {
                      if (!name) return true
                      startLoading()
                      const { isUnique } = await datalabFacade.isADUsernameUnique(name + AAD_SUFFIX)
                      stopLoading()
                      return isUnique
                    }
                  )
                  .required()
                  .label('userPrincipalName'),
              displayName: Yup.string()
                .matches(
                  /^\w+([!@#\\$%\\^\\&*\\)\\( +=._-]*\w+)*$/,
                  'May only contain characters, numbers and special characters, no tabs'
                )
                .min(2)
                .trim()
                .required()
                .label('Display name'),
              email: Yup.string().email()
                .required(),
              readOnly: updating ? Yup.boolean() : Yup.boolean()
                .required(),
              dataAdmin: updating ? Yup.boolean() : Yup.boolean()
                .required(),
              userAdmin: updating ? Yup.boolean() : Yup.boolean()
                .required(),
            })}
          >

            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                updating={updating}
              />
            )}

          </Formik>
        </Container>
      </div>
    )
}

NewAdministratorScreen.propTypes = propTypes
NewAdministratorScreen.defaultProps = defaultProps
export default wrap(NewAdministratorScreen)

