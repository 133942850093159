import { asyncOperationResponse } from '@datalab/dto-schemas'
import { StoreAction } from '../enums/StoreAction'
import { ApiOperation } from '../enums/ApiOperation'
import { getRuntimeConfig } from '../util/Config'
import { UserFixableError } from '../util/Exceptions'
import AuthenticationHandler from '../util/AuthenticationHandler'
import DataService from './DataService'
import type {
  FindAllPaginatedQuery,
  FindAllPaginatedQueryResponse,
  FindAllPaginatedQueryResponseFields,
} from '@datalab/dto-schemas'

const config = getRuntimeConfig()

export default class DatalabFacade {
  dataService: typeof DataService

  apiBaseUrl = config.PROPERTIES_STAGE === 'local' ? 'https://datalab.dev.abs.gov.au/api/operations'
    : `${window.location.origin}/api/operations`

  asyncOperationDispatcher: unknown

  authenticationHandler: AuthenticationHandler

  constructor(authenticationHandler: AuthenticationHandler, asyncOperationDispatcher: unknown) {
    this.authenticationHandler = authenticationHandler
    this.dataService = DataService
    this.asyncOperationDispatcher = asyncOperationDispatcher
  }

  static checkBlockWrites() {
    if (config.MAINTENANCE_BLOCK_WRITES === 'true') {
      throw new UserFixableError(`This feature is temporarily disabled. ${config.MAINTENANCE_MESSAGE}`)
    }
  }

  static checkBlockPodOverride() {
    if (window.localStorage.getItem('sysadmin.pod_override')) {
      throw new UserFixableError('Currently read-only access when using pod override')
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async invokeAsyncApiOperation(endpoint, requestBody, snackbarTracker?) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(accessToken, this.apiBaseUrl + endpoint, requestBody)
    const { eventId } = asyncOperationResponse.parse(result.data)
    if (!eventId) {
      throw new Error('An async operation returned successfully but didnt provide an eventId')
    }
    let snackbar
    // if tracking via snackbar
    if (snackbarTracker) {
      snackbar = snackbarTracker()
    }
    // push the async operation on the queue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- The redux store needs typing
    this.asyncOperationDispatcher({
      type: StoreAction.ADD_OPERATION,
      operation: {
        eventId, time: new Date().getTime(), endpoint, requestBody, snackbar,
      },
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getEventsByIds(eventIds) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_EVENT,

      { eventIds }
    )
    return data
  }

  async getDatabricksWorkspaces() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_DATABRICKS_WORKSPACES
    )
    return data
  }

  async getVMOptionsConfig() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_VM_OPTIONS_CONFIG
    )
    return data
  }

  /**
   * List documents in a collection
   * @param params - Options for the list operation
   * @returns The documents
   */
  async list<
    T extends FindAllPaginatedQuery &
    { requestBody: { fields:(keyof FindAllPaginatedQueryResponseFields[T['entity']])[] } }
  >(params: T): Promise<FindAllPaginatedQueryResponse<T['entity'], T['requestBody']['fields']>> {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const { entity, requestBody } = params
    const result = await this.dataService.postOperation(
      accessToken,
      `${this.apiBaseUrl}/list/${entity}`,
      requestBody
    )
    return result.data as FindAllPaginatedQueryResponse<T['entity'], T['requestBody']['fields']>
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getUsers(podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_USERS,

      { podId }
    )
    return data
  }

  async getCurrentLicenseData() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_CURRENT_LICENSE_DATA
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getProject(projectId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const project = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_PROJECT,

      { projectID: projectId }
    )
    return project
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getUserProjects(userName) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const projects = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_USER_PROJECTS,

      { userName }
    )
    return projects
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getUser(userName) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const user = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_USER,

      { userName }
    )
    return user
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getADUser(userName) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const user = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_AD_USER,

      { userPrincipalName: userName }
    )
    return user
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getVm(VMname) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_VM,

      { vmName: VMname }
    )
  }

  async getVms() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_VMS,

      {}
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getVmState(VMname) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_VM_STATE,

      { vmName: VMname }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getVmBypassShutdown(vmName) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_VM_BYPASS_SHUTDOWN,

      { vmName }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async setVmBypassShutdown(vmName, bypassDays) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.SET_VM_BYPASS_SHUTDOWN,

      { vmName, bypassDays }
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async provisionProjectToDatabricks(projectId, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(
      ApiOperation.PROVISION_PROJECT_DATABRICKS,
      { projectId, podId }
    )
  }

  /**
   * Delete the databricks workspace associated with a project
   * @param projectId - The project identifier
   * @param podId     - The pod identifier
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async deleteDatabricks(projectId, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(
      ApiOperation.DELETE_DATABRICKS,
      { projectId, podId }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async startVM(vmName, podId, snackbarTracker) {
    await this.invokeAsyncApiOperation(ApiOperation.START_VM, { vmName, podId }, snackbarTracker)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async stopVM(vmName, podId) {
    await this.invokeAsyncApiOperation(ApiOperation.STOP_VM, { vmName, podId })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async createProject(project) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.CREATE_PROJECT, project)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async restoreProject(projectId, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.RESTORE_PROJECT, { uuid: projectId, podId })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async isUsernameUnique(userName) {
    const body = {
      userName,
    }
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.IS_USERNAME_UNIQUE,

      body
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async isADUsernameUnique(userName) {
    const body = {
      userPrincipalName: userName,
    }
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.IS_AD_USERNAME_UNIQUE,

      body
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async isProjectidUnique(uuid) {
    const body = {
      uuid,
    }
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.IS_PROJECTID_UNIQUE,

      body
    )
    return result.data
  }

  /**
   * Invoke the add packages api endpoint
   * @param packageNames - Array of package names
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async addRPackages(packageNames) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.ADD_R_PACKAGES, { packages: packageNames })
  }

  /**
   * Invoke the add packages api endpoint
   * @param packageNames - Array of package names
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async addPythonPackages(packageNames) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.ADD_PYTHON_PACKAGES, { packages: packageNames })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async closeProject(projectId, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.CLOSE_PROJECT, { projectId, podId })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async disableUser(userName) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.DISABLE_USER, { userName })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async enableUser(userName) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.ENABLE_USER, { userName })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async createUser(user) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.REGISTER_USER, user)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async createAdministrator(user) {
    DatalabFacade.checkBlockWrites()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.CREATE_ADMINISTRATOR,

      user
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async deleteADUser(userPrincipalName, podId) {
    DatalabFacade.checkBlockWrites()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.DELETE_AD_USER,
      { userPrincipalName, podId }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async resetADUserMFA(userName) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.RESET_AD_USER_MFA,
      { userName }
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateADUser(user) {
    DatalabFacade.checkBlockWrites()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(accessToken, this.apiBaseUrl + ApiOperation.UPDATE_AD_USER, user)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async togglePodAdminRole(userPrincipalName, type, enabled) {
    DatalabFacade.checkBlockWrites()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.TOGGLE_POD_ADMIN_ROLE,
      { userPrincipalName, type, enabled }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async restoreUser(user) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.RESTORE_USER, user)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateUser(user) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(accessToken, this.apiBaseUrl + ApiOperation.UPDATE_USER, user)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateUserSelfService(user) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.UPDATE_USER_SELF_SERVICE,

      user
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateProject(project) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const projectRequest = project
    projectRequest.projectId = project.uuid
    delete projectRequest.uuid
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.UPDATE_PROJECT,
      projectRequest
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async evictUser(userName) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.DELETE_USER, { userName })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async unassignUserFromProject(userName, projectId, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const submitData = {
      projectId,
      userName,
      podId,
    }
    await this.invokeAsyncApiOperation(ApiOperation.UNASSIGN_USER, submitData)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async unassignUsersFromProject(userNames, projectId, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const submitData = {
      projectId,
      userNames,
      podId,
    }
    await this.invokeAsyncApiOperation(ApiOperation.UNASSIGN_USERS, submitData)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async unassignAllProjects(userName, projectIds, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const submitData = {
      projectIds,
      userName,
      podId,
    }
    await this.invokeAsyncApiOperation(ApiOperation.UNASSIGN_USER_FROM_ALL_PROJECTS, submitData)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async assignUserToProject(userName, projectId, size, type, version, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const submitData = {
      size,
      type,
      version,
      projectId,
      userName,
      podId,
    }
    await this.invokeAsyncApiOperation(ApiOperation.ASSIGN_USER, submitData)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async assignUsersToProject(userNames, projectId, size, type, version, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const submitData = {
      size,
      type,
      version,
      projectId,
      userNames,
      podId,
    }
    await this.invokeAsyncApiOperation(ApiOperation.ASSIGN_USERS, submitData)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async activateVm(vmName, podId) {
    await this.invokeAsyncApiOperation(ApiOperation.ACTIVATE_VM, { vmName, podId })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getProducts(podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_PRODUCTS,

      { podId }
    )
    return data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getProduct(name, podId) {
    const requestBody = {
      name,
      podId,
    }
    const accessToken = await this.authenticationHandler.getAccessToken()
    const product = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_PRODUCT,

      requestBody
    )
    return product
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getProjectsProducts(projectId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_PROJECTS_PRODUCTS,

      { projectId }
    )
    return data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async createProduct(product) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.CREATE_PRODUCT, product)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async deleteProduct(name, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.DELETE_PRODUCT, { name, podId })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateProduct(product) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.UPDATE_PRODUCT,

      product
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async linkProjectToProducts(projectId, productNames) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.LINK_PROJECT_TO_PRODUCTS, { projectId, productNames })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateProductLinks(body) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.UPDATE_PRODUCT_LINKS, body)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async resizeVm(resourceGroupName, vmName, vmSize) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const requestBody = {
      resourceGroupName,
      vmName,
      vmSize,
    }
    await this.invokeAsyncApiOperation(ApiOperation.RESIZE_VM, requestBody)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async changeVmType(vmName, type, version, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const requestBody = {
      vmName,
      type,
      version,
      podId,
    }
    await this.invokeAsyncApiOperation(ApiOperation.CHANGE_VM_TYPE, requestBody)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async restartVm(vmName, podId, snackbarTracker) {
    await this.invokeAsyncApiOperation(ApiOperation.RESTART_VM, { vmName, podId }, snackbarTracker)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async rebuildVm(vmName, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.REBUILD_VM, { vmName, podId, touched: true })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getOrganisations(podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_ORGANISATIONS,

      { podId }
    )
    return data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getPodAdmins(podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.LIST_ADMINS,

      { podId }
    )
    return data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getOrganisation(organisationId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const organisation = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_ORGANISATION,

      { organisationId }
    )
    return organisation
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async isOrganisationNameUnique(organisationName) {
    const body = {
      organisationName,
    }
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.IS_ORGANISATIONNAME_UNIQUE,

      body
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async isABNUnique(ABN) {
    const body = {
      ABN,
    }
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.IS_ABN_UNIQUE,

      body
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async createOrganisation(organisation) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.CREATE_ORGANISATION,

      organisation
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async deleteOrganisation(organisationId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const body = {
      organisationId,
    }

    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.DELETE_ORGANISATION,

      body
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateOrganisation(organisation) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.UPDATE_ORGANISATION,

      organisation
    )
  }

  async getAadUsers() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_AAD_USERS
    )
  }

  async getReports() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const data = await this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_REPORTS
    )
    return data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getReport(fileName) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchOne(
      accessToken,
      `${this.apiBaseUrl + ApiOperation.GET_REPORTS}/${fileName}`,

      {}
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async removeFileHandlers(productName, podId) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.REMOVE_FILE_HANDLERS,

      { productName, podId }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async resetUserPassword(userName, tempPassword) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.RESET_USER_PASSWORD,

      { userName, tempPassword }
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async configureVmLocalDisk(vmName, localDiskSize) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    await this.invokeAsyncApiOperation(ApiOperation.CONFIGURE_VM_LOCAL_DISK, { vmName, localDiskSize })
  }

  async getMonthlyActiveDesktops() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_MONTHLY_ACTIVE_DESKTOPS
    )
    return result
  }

  async getMonthlyActiveUsers() {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_MONTHLY_ACTIVE_USERS
    )
    return result
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async resetUserMFA(userName) {
    DatalabFacade.checkBlockWrites()
    DatalabFacade.checkBlockPodOverride()
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.RESET_USER_MFA,

      { userName }
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getSoftwareLicenseSummaries(startDate, endDate, softwareNames) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_SOFTWARE_LICENSE_SUMMARIES,

      { startDate, endDate, softwareNames }
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getTags(podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_TAGS,
      { podId }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getTag(tagKey, podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.fetchOne(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_TAG,
      { tagKey, podId }
    )
    return result
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async createTag(tag) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    const result = await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.CREATE_TAG,

      tag
    )
    return result.data
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async updateTag(tag) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.UPDATE_TAG,

      tag
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async getBannerMessage(podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    return this.dataService.fetchData(
      accessToken,
      this.apiBaseUrl + ApiOperation.GET_BANNER_MESSAGE,
      { podId }
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async postBannerMessage(message) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.POST_BANNER_MESSAGE,

      message
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  async deleteTag(tagKey, podId) {
    const accessToken = await this.authenticationHandler.getAccessToken()
    await this.dataService.postOperation(
      accessToken,
      this.apiBaseUrl + ApiOperation.DELETE_TAG,
      { tagKey, podId }
    )
  }
}
