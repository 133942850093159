"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectDtoSchema = exports.projectInputFields = void 0;
const zod_1 = require("zod");
const tag_1 = require("../tag");
/** Database fields set by user input that can be queried via the api */
const projectInputFields = zod_1.z.object({
    /** The identifier of the project */
    uuid: zod_1.z.string()
        .min(3)
        .max(8)
        .regex(/^[a-z0-9]{3,8}$/),
    /** The display name of the project */
    projectName: zod_1.z.string()
        .min(3)
        .max(100),
    /** The description of the project */
    description: zod_1.z.string(),
    /** The identifier of the pod that the project resides in */
    projectContact: zod_1.z.string()
        .max(50),
    /** The email of the contact person who leads the project */
    contactEmail: zod_1.z.string()
        .email(),
    /** The phone number of the contact person who leads the project */
    contactPhone: zod_1.z.string()
        .min(8)
        .max(16)
        .regex(/^\+?[0-9 ]*$/),
    /** The date the project is expected to end */
    endDate: zod_1.z.string().datetime(),
    /** The size that will be selected by default for new VMs in the project */
    defaultVMsize: zod_1.z.string(),
    /** The organisation name of the project */
    organisation: zod_1.z.string(),
    /** The storage size in TiB for the 'project' Azure file share deployed for the project to use as a workspace */
    projectStorageSize: zod_1.z.number(),
    /** The storage size in TiB for the 'datalake' blob container deployed for the project to use within databricks */
    projectStorageSizeDatalake: zod_1.z.number(),
    /** Tag instances to associate with the project */
    tags: zod_1.z.array(tag_1.tagInstanceDtoSchema)
        .default([])
        .optional(),
    /** Users that are members of the project */
    users: zod_1.z.array(zod_1.z.string()).optional(),
});
exports.projectInputFields = projectInputFields;
/** Generated database fields that can be queried via the api */
const projectGeneratedFields = zod_1.z.object({
    /** The date the project started */
    startDate: zod_1.z.string().datetime(),
    closedDate: zod_1.z.string().datetime().optional(),
    status: zod_1.z.enum(['OPEN', 'BUILDING', 'CLOSED', 'ARCHIVED']),
    databricksWorkspace: zod_1.z.string().optional(),
});
/** All database fields that are exposed via API */
const projectDtoSchema = projectInputFields.merge(projectGeneratedFields);
exports.projectDtoSchema = projectDtoSchema;
